import { defineStore } from 'pinia';

export const useMainStore = defineStore({
  id: 'main',
  state: () => ({
    breachCount: null,
  }),
  getters: {},
  actions: {
    setBreachCount(count) {
      this.breachCount = count;
    },
  },
});
