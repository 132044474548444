import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import VueCryptojs from 'vue-cryptojs';
import { createPinia } from 'pinia';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/HomeView.vue'),
    },
    {
      path: '/commitment',
      name: 'Commitment',
      component: () => import('./views/CommitmentView.vue'),
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import('./views/SearchView.vue'),
    },
    {
      path: '/terms',
      name: 'Terms of Service',
      component: () => import('./views/TermsView.vue'),
    },
    {
      path: '/privacy',
      name: 'Privacy Policy',
      component: () => import('./views/PrivacyView.vue'),
    },
  ],
});

const app = createApp(App);

app.use(VueCryptojs);
app.use(router);
app.use(createPinia());
app.mount('#app');
